<template>
  <div>
    <div class="chooseroombox mb-2" v-if="device === 'mobile'">
      <button class="js-del-row" v-if="index > 0" @click="remove">
        <i class="far fa-window-close"></i>
      </button>
      <h2>
        {{ $t('product-page.room') }} {{ index + 1 }} :
        {{ $t('product-page.choose-room') }}
      </h2>
      <div class="row row-0 mx-n2">
        <div class="col-12 p-1">
          <div class="form-group">
            <PaxInformation
              :topButton="false"
              :tourists="tourists"
              @completedPaxes="completedPaxes"
            />
          </div>
        </div>
        <div class="col-6 p-1">
          <div class="form-group">
            <b-form-select
              class="rounded"
              v-model="currentTypeCode"
              :options="listRoomTypes"
            ></b-form-select>
          </div>
        </div>
        <div class="col-6 p-1">
          <div class="form-group">
            <b-form-select
              class="rounded"
              v-model="currentBoardCode"
              :options="listBoard"
            ></b-form-select>
          </div>
        </div>
        <!-- <div class="col-4">
        </div> -->
        <div class="col-12 ptag">
          <p class="pl-2 mb-1">
            {{ $t('product-page.price-room') }}
          </p>
          <p class="mb-2 p-price">
            <strong class="mx-3"
              >{{ symbolCurrency }} {{ currentPrice }}</strong
            >
            {{ codeCurrency }}
          </p>
        </div>
      </div>
    </div>
    <div class="card-body" v-else>
      <h5>
        <strong v-if="lang === 'he'">{{ roomTypeName }}, {{ $t('product-page.room') }}
          {{ $t(`product-page.ordered-number[${index}]`) }} :
          {{ personString }}</strong>
        <strong v-else>{{ roomTypeName }},
          {{ $t(`product-page.ordered-number[${index}]`) }}
          {{ $t('product-page.room') }} : {{ personString }}</strong>
      </h5>
      <table class="table">
        <thead>
          <tr>
            <th class="text-center font-weight-bold">{{ $t('product-page.paxes') }}</th>
            <th class="text-center font-weight-bold">{{ $t('product-page.room-type') }}</th>
            <th class="text-center font-weight-bold">{{ $t('product-page.board') }}</th>
            <th class="text-center font-weight-bold">{{ $t('product-page.price') }}</th>
          </tr>
        </thead>
        <tr>
          <td>
            <PaxInformation :topButton="false" :tourists="tourists" @completedPaxes="completedPaxes"/>
          </td>
          <td>
            <b-form-select v-model="currentTypeCode" :options="listRoomTypes"></b-form-select>
          </td>
          <td>
            <b-form-select v-model="currentBoardCode" :options="listBoard"></b-form-select>
          </td>
          <td>
            <b-spinner v-if="priceLoading"></b-spinner>
            <div class="w-100" v-else>
              <span>{{ symbolCurrency }}{{ currentPrice }}</span>
              <p>{{ codeCurrency }}</p>
            </div>
          </td>
        </tr>
      </table>

      <button class="js-del-row" v-if="index > 0" @click="remove">
        <i class="far fa-window-close"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { BFormSelect, BSpinner } from 'bootstrap-vue';
import getSymbolFromCurrency from 'currency-symbol-map';

export default {
  components: {
    PaxInformation: () => import('@/components/atoms/PaxInformation'),
    BFormSelect,
    BSpinner,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      activeHotelData: null,
      filterKey: {
        type: '',
        board: '',
        price: 0,
      },
      candidateRooms: [],

      personString: '',

      roomTypeName: '',
      currentTypeCode: '',
      listRoomTypes: [],

      currentBoardCode: '',
      listBoard: [],

      currentPrice: 0,
      priceLoading: false,

      detailTourists: null,

      rate: null,
    };
  },
  watch: {
    async currentTypeCode(value, old) {
      if (old === value) return;
      this.filterKey.type = value;
      this.roomTypeName = this.getRoomTypeFromCode(value);
      await this.chooseRoom(1);
    },
    async currentBoardCode(value, old) {
      if (old === value) return;
      this.filterKey.board = value;
      await this.chooseRoom(2);
    },
    priceLoading() {
      this.$emit('setPriceLoading', this.priceLoading);
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      device: 'GET_DEVICE',
    }),
    tourists() {
      return this.detailTourists;
    },
    symbolCurrency() {
      return getSymbolFromCurrency(this.data.currency);
    },
    codeCurrency() {
      return `(${this.data.currency})`;
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      const tmpTourists = {
        adult: 0,
        child: 0,
        infant: 0,
        listAgeChildren: [],
        listAgeInfants: [],
      };
      tmpTourists.adult = Number(this.data?.occupancy?.adults);
      this.data.occupancy.paxes.forEach((ageInfo) => {
        const { age } = ageInfo;
        if (age <= 2) {
          tmpTourists.child += 1;
          tmpTourists.listAgeInfants[tmpTourists.child] = age;
        } else {
          tmpTourists.infant += 1;
          tmpTourists.listAgeChildren[tmpTourists.infant] = age;
        }
      });
      this.detailTourists = tmpTourists;

      const query = {
        stay: this.data.stay,
        occupancies: [this.data.occupancy],
        hotels: {
          hotel: [this.data.hotelId],
        },
      };
      this.updateActiveHotel(query);
    },
    async updateActiveHotel(pQuery) {
      this.priceLoading = true;
      const { adults, children } = pQuery.occupancies[0];
      this.personString = this.getPersonString(adults, children);
      const response = await this.$store.dispatch(
        'FETCH_HOTEL_AVAILABILITY',
        pQuery,
      );
      const activeHotel = response.data;
      this.$emit('updatedActiveHotel', activeHotel.hotels.hotels[0]);
      if (activeHotel.hotels.total > 0) {
        this.activeHotelData = activeHotel.hotels.hotels[0];
        this.constructCandidateRooms();
        await this.chooseRoom(0);
      } else {
        this.candidateRooms = [];
        this.listRoomTypes = [];
        this.listBoard = [];
        this.currentPrice = 0;
      }
      this.priceLoading = false;
    },
    constructCandidateRooms() {
      this.activeHotelData.rooms.forEach((room) => {
        let indexOR = room.name.indexOf('or') || room.name.indexOf('OR') || -1;
        indexOR = -1;
        if (indexOR !== -1) {
          const type1 = {
            value: room.code,
            text: room.name.substr(0, indexOR).trim(),
          };
          const type2 = {
            value: room.code,
            text: room.name.substr(indexOR + 2).trim(),
          };
          room.rates.forEach((rate) => {
            let price = Number(rate.net);
            price += price * 0.2;
            this.candidateRooms.push({
              type: type1,
              board: { value: rate.boardCode, text: rate.boardName },
              price,
              rate: {
                rateType: rate.rateType,
                rateKey: rate.rateKey,
              },
            });
            this.candidateRooms.push({
              type: type2,
              board: { value: rate.boardCode, text: rate.boardName },
              price,
              rate: {
                rateType: rate.rateType,
                rateKey: rate.rateKey,
              },
            });
          });
        } else {
          const type = { value: room.code, text: room.name };
          room.rates.forEach((rate) => {
            let price = Number(rate.net);
            price += price * 0.2;
            this.candidateRooms.push({
              type,
              board: { value: rate.boardCode, text: rate.boardName },
              price,
              rate: {
                rateType: rate.rateType,
                rateKey: rate.rateKey,
              },
            });
          });
        }
      });
      this.candidateRooms.sort((room1, room2) => room1.price - room2.price);
    },
    constructRoomTypes(pRooms) {
      this.listRoomTypes = [];
      pRooms.forEach((room) => {
        if (
          !this.listRoomTypes.find(
            (currType) => currType.value === room.type.value
              && currType.text === room.type.text,
          )
        ) {
          this.listRoomTypes.push(room.type);
        }
      });
    },
    getRoomTypeFromCode(pCode) {
      return this.listRoomTypes.find((type) => type.value === pCode).text;
    },
    constructRoomBoard(pRooms) {
      this.listBoard = [];
      pRooms.forEach((room) => {
        if (!this.listBoard.find((board) => board.value === room.board.value)) {
          this.listBoard.push(room.board);
        }
      });
    },
    getBoardNameFromCode(pCode) {
      return this.listBoard.find((board) => board.value === pCode).text;
    },
    async chooseRoom(pStage) {
      this.priceLoading = true;
      let filterList = this.candidateRooms;

      if (pStage === 0) {
        this.constructRoomTypes(filterList);
        this.constructRoomBoard(filterList);
      } else if (pStage === 1) {
        filterList = filterList.filter(
          (room) => room.type.value === this.filterKey.type,
        );
        this.constructRoomBoard(filterList);
      } else if (pStage === 2) {
        filterList = filterList.filter(
          (room) => room.type.value === this.filterKey.type,
        );
        filterList = filterList.filter(
          (room) => room.board.value === this.filterKey.board,
        );
      }

      if (filterList.length === 0) {
        this.currentTypeCode = '';
        this.currentBoardCode = '';
        this.currentPrice = 0;
        this.rate = null;
      } else {
        this.currentTypeCode = filterList[0].type.value;
        this.currentBoardCode = filterList[0].board.value;
        if (filterList[0].rate.rateType === 'RECHECK') {
          this.priceLoading = true;
          const response = await this.$store.dispatch('SEND_CHECK_RATE', {
            rooms: [{ rateKey: filterList[0].rate.rateKey }],
          });
          if (response.success) {
            const rate = response.data.hotel.rooms[0].rates[0];
            let price = Number(rate.net);
            price += price * 0.2;
            filterList[0].price = Number(price);
            filterList[0].rate.rateType = 'BOOKABLE';
          }
          this.priceLoading = false;
        }
        this.currentPrice = Math.round(filterList[0].price);
        this.rate = filterList[0].rate;
      }

      this.updateItem();

      this.priceLoading = false;
    },
    getPersonString(adult = 0, child = 0, infant = 0) {
      let str = '';
      if (adult === 1) {
        str = `${adult} ${this.$t('product-page.adult')}`;
      } else {
        str = `${adult} ${this.$t('product-page.adults')}`;
      }
      if (child === 1) {
        str += ` + ${child} ${this.$t('product-page.child')}`;
      } else if (child > 1) {
        str += ` + ${child} ${this.$t('product-page.children')}`;
      }
      if (infant === 1) {
        str += ` + ${infant}  ${this.$t('product-page.infant')}`;
      } else if (infant > 1) {
        str += ` + ${infant}  ${this.$t('product-page.infants')}`;
      }
      return str;
    },
    completedPaxes(pOccupancy) {
      this.detailTourists = pOccupancy;
      const paxes = [];
      pOccupancy.listAgeChildren.forEach((age) => {
        paxes.push({
          type: 'CH',
          age: Number(age),
        });
      });
      pOccupancy.listAgeInfants.forEach((age) => {
        paxes.push({
          type: 'CH',
          age: Number(age),
        });
      });
      const occupancy = {
        rooms: 1,
        adults: pOccupancy.adult,
        children: pOccupancy.child + pOccupancy.infant,
        paxes,
      };
      const query = {
        stay: this.data.stay,
        occupancies: [occupancy],
        hotels: {
          hotel: [this.data.hotelId],
        },
      };
      this.updateActiveHotel(query);
    },
    async updateItem() {
      let updateData = null;
      if (this.currentPrice === 0) {
        updateData = {
          index: this.index,
          hotelId: '',
          roomClass: null,
          roomType: null,
          roomBasis: '',
          roomBasisCode: '',
          price: 0,
          adult: 0,
          child: 0,
          infant: 0,
          totalPrice: 0,
          oldPrice: 0,
          oldTotal: 0,
          rate: null,
        };
      } else {
        updateData = {
          index: this.index,
          hotelId: this.data.hotelId,
          roomClass: null,
          rate: this.rate,
          roomType: {
            value: this.currentTypeCode,
            text: this.getRoomTypeFromCode(this.currentTypeCode),
          },
          roomBasis: this.getBoardNameFromCode(this.currentBoardCode),
          roomBasisCode: this.currentBoardCode,
          price: this.currentPrice,
          adult: this.detailTourists.adult,
          child: this.detailTourists.child,
          infant: this.detailTourists.infant,
          priceInf: null,
          totalPrice: this.currentPrice, // TODO: should sum infant value
          oldPrice: this.currentPrice,
          oldTotal: this.currentPrice,
        };
      }
      this.$emit('update', updateData);
    },
    remove() {
      this.$emit('closed', this.index);
    },
  },
};
</script>

<style scoped>
s {
  opacity: 0.5;
}
.ptag {
  text-align: center;
}
.p-price {
  display: inline-flex;
  align-items: baseline;
}
.table > thead > tr > th {
  padding: 12px 3px;
  text-align: left;
}
.table td {
  padding: 0.75rem 0.5rem;
  vertical-align: middle;
  text-align: center;
}
.custom-select {
  padding: 0.375rem 1.55rem 0.375rem 0.5rem;
}
@media (max-width: 479px) {
  .card-body {
    padding: 0.25rem;
  }
  .flight-details-leftbox .table > tbody > tr > td,
  .flight-details-leftbox .table > tbody > tr > th,
  .flight-details-leftbox .table > tfoot > tr > td,
  .flight-details-leftbox .table > tfoot > tr > th,
  .flight-details-leftbox .table > thead > tr > td,
  .flight-details-leftbox .table > thead > tr > th {
    padding: 4px;
  }
}
.form-check-input {
  right: -15px;
}
</style>
